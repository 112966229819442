<script lang="ts" setup>
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();

// Data
const submiting = ref(false);
const modal = defineModel<boolean>();
const route = useRoute();

// Store
const service = productStore();

// Computed
const product = computed(() => {
  return service.currentProduct;
});
const inputText = computed(() => {
  const unit = product.value?.fabrication_order_products[0]?.fabrication_order?.area?.unit?.symbol ?? 'kg';
  return `kWh / ${unit}`;
});

// Form
const schema = yup.object({
  epi_standard: yup.number().required(t('product.form.epi_target_is_required')).typeError(t('product.form.epi_target_is_required')),
});
const { handleSubmit, setValues } = useForm<{ epi_standard: number }>({
  validationSchema: schema,
  initialValues: {
    epi_standard: product.value?.epi_standard ?? 0,
  },
});

// Methods
const submit = handleSubmit(async (values) => {
  submiting.value = true;
  await service.updateProduct(parseInt(route.params.id as string), values);
  await service.fetchProduct(parseInt(route.params.id as string));
  submiting.value = false;
  modal.value = false;
});

watch(
  () => [product.value, modal.value],
  () => {
    if (product.value) {
      setValues({ epi_standard: product.value.epi_standard });
    }
  },
  {
    immediate: true,
  },
);
</script>
<template>
  <ui-modal
    v-model="modal"
    width="400"
    :title="product?.epi_standard ? $t('product.modal.update_epi_target') : $t('product.modal.define_epi_target')"
  >
    <ui-form-input-text
      type="number"
      placeholder="0.5"
      required
      name="epi_standard"
      :label="$t('product.modal.epiTarget')"
      :right-text="inputText"
    />
    <template #footer>
      <ui-button color="secondary" @click="modal = false">{{ $t('global.cancel') }}</ui-button>
      <ui-button color="primary" @click="submit()">{{ $t('global.save') }}</ui-button>
    </template>
  </ui-modal>
</template>
