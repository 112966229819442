import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';

export const useOfTable = () => {
  // Composables
  const { t } = useI18n();

  const headers: Header[] = [
    {
      label: t('product.headers.of_identifier'),
      key: 'of_id',
    },
    {
      label: t('product.headers.start_date'),
      key: 'start_date',
      sortable: 'start-date',
    },
    {
      label: t('product.headers.duration'),
      key: 'duration',
    },
    {
      label: t('product.headers.quantity'),
      key: 'quantity',
      sortable: 'quantiy',
    },
    {
      label: t('product.headers.epi'),
      key: 'epi',
      sortable: 'epi',
    },
    {
      label: t('product.headers.area'),
      key: 'machine',
    },
    {
      label: '',
      key: 'action',
      align: 'center',
    },
  ];

  return {
    headers,
  };
};
